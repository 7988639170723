import React from "react";
import { useTransactions } from "./useBitcoinAPI";

const TransactionHistory: React.FC = () => {
  const { transactions, loading } = useTransactions();

  if (loading) return <p>Loading transactions...</p>;

  return (
    <div>
      <h2>Transaction History</h2>
      {transactions.length > 0 ? (
        <table>
          <thead>
            <tr>
              <th>Type</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((tx) => (
              <tr key={tx.id}>
                <td>{tx.transaction_type}</td>
                <td>{tx.amount} BTC</td>
                <td>{new Date(tx.date).toLocaleString()}</td>
                <td>{tx.notes}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No transactions found.</p>
      )}
    </div>
  );
};

export default TransactionHistory;


// import React from "react";
// import { useTransactions } from "./useBitcoinAPI";

// const TransactionHistory: React.FC = () => {
//   const { transactions, loading } = useTransactions();

//   if (loading) return <p>Loading transactions...</p>;

//   return (
//     <div>
//       <h2>Transaction History</h2>
//       {transactions.length > 0 ? (
//         <table>
//           <thead>
//             <tr>
//               <th>Type</th>
//               <th>Amount</th>
//               <th>Date</th>
//               <th>Notes</th>
//             </tr>
//           </thead>
//           <tbody>
//             {transactions.map((tx) => (
//               <tr key={tx.id}>
//                 <td>{tx.transaction_type}</td>
//                 <td>{tx.amount} BTC</td>
//                 <td>{new Date(tx.date).toLocaleString()}</td>
//                 <td>{tx.notes}</td>
//               </tr>
//             ))}
//           </tbody>
//         </table>
//       ) : (
//         <p>No transactions found.</p>
//       )}
//     </div>
//   );
// };

// export default TransactionHistory;


// import React, { useEffect, useState } from 'react';

// const TransactionHistory: React.FC = () => {
//     const [transactions, setTransactions] = useState([]);

//     useEffect(() => {
//         const fetchTransactions = async () => {
//             const response = await fetch('/api/wallet/transactions/');
//             const data = await response.json();
//             setTransactions(data);
//         };
//         fetchTransactions();
//     }, []);

//     return (
//         <div>
//             <h2>Transaction History</h2>
//             <ul>
//                 {transactions.map((tx:any, index) => (
//                     <li key={index}>
//                         {tx.txId} - {tx.amount} BTC
//                     </li>
//                 ))}
//             </ul>
//         </div>
//     );
// };

// export default TransactionHistory;

// path('w/create/', CreateWalletView.as_view(), name='create_wallet'),
// path('w/balance/', WalletBalanceView.as_view(), name='wallet_balance'),
// path('w/send/', SendBitcoinView.as_view(), name='send_bitcoin'),
// path('w/transactions/', TransactionHistoryView.as_view(), name='transaction_history'),