import React, { useState, useEffect } from "react";
import { IChildPanelInfoProps } from "../../../types/main";
import { API_BASE_URL } from "../../usePostManager";
import UserInfo from "../user-info/UserInfo";

interface IPAccessLog {
  id: number;
  ip_address: string;
  status: string;
  last_accessed: string;
  request_count: number;
}

const System: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {
  const [logs, setLogs] = useState<IPAccessLog[]>([]);
  const [selectedLogs, setSelectedLogs] = useState<Set<string>>(new Set());
  const [search, setSearch] = useState<string>("");
  const [filterStatus, setFilterStatus] = useState<string>("");

  // Fetch data from the API
  const fetchLogs = async () => {
    const response = await fetch(API_BASE_URL+"/e/ip-access-logs/");
    const data: IPAccessLog[] = await response.json();
    setLogs(data);
  };

  useEffect(() => {
    fetchLogs();
  }, []);

  // Handle row selection
  const toggleSelection = (ip: string) => {
    setSelectedLogs((prev) => {
      const updated = new Set(prev);
      updated.has(ip) ? updated.delete(ip) : updated.add(ip);
      return updated;
    });
  };

  // Approve selected IPs
  const approveSelected = async () => {
    const response = await fetch(API_BASE_URL+"/e/ip-access-logs/approve", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ips: Array.from(selectedLogs) }),
    });
    if (response.ok) fetchLogs();
  };

  // Block selected IPs
  const blockSelected = async () => {
    const response = await fetch(API_BASE_URL+"/e/ip-access-logs/block", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ips: Array.from(selectedLogs) }),
    });
    if (response.ok) fetchLogs();
  };

  // Filter logs
  const filteredLogs = logs
    .filter((log) =>
      log.ip_address.toLowerCase().includes(search.toLowerCase())
    )
    .filter((log) => (filterStatus ? log.status === filterStatus : true));

  return (
    <div>
      <UserInfo childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} />
      <h1>IP Access Logs</h1>

      <div>
        <input
          type="text"
          placeholder="Search by IP..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <select
          value={filterStatus}
          onChange={(e) => setFilterStatus(e.target.value)}
        >
          <option value="">All</option>
          <option value="new">New</option>
          <option value="allowed">Allowed</option>
          <option value="blocked">Blocked</option>
        </select>
      </div>

      <table>
        <thead>
          <tr>
            <th>Select</th>
            <th>IP Address</th>
            <th>Status</th>
            <th>Last Accessed</th>
            <th>Request Count</th>
            <th>Ok</th>
            <th>Not Ok</th>
          </tr>
        </thead>
        <tbody>
          {filteredLogs.map((log) => (
            <tr key={log.ip_address}>
              <td>
                <input
                  type="checkbox"
                  checked={selectedLogs.has(log.ip_address)}
                  onChange={() => toggleSelection(log.ip_address)}
                />
              </td>
              <td>{log.ip_address}</td>
              <td>{log.status}</td>
              <td>{new Date(log.last_accessed).toLocaleString()}</td>
              <td>{log.request_count}</td>
              <td>
                {/* <button onClick={approveSelected(log.ip_address)}>Approve Selected</button> */}
                </td>
              <td>
                {/* <button onClick={blockSelected(log.ip_address)}>Block Selected</button> */}
                </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div>
        <button onClick={approveSelected} className="width-spacer-10">Approve Selected</button>
        <button onClick={blockSelected} style={{backgroundColor: childPanelInfoState.mainPanelInfo.styles.dangerButtonColor}}>Block Selected</button>
      </div>
    </div>
  );
};

export default System;

// import React, { useState, useEffect } from 'react';
// import ReconnectingWebSocket from 'reconnecting-websocket';
// import { IChildPanelInfoProps } from '../../../types/main';

// interface Message {
//   username: string;
//   message: string;
// }

// const System: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {
//   const [messages, setMessages] = useState<Message[]>([]);
//   const [input, setInput] = useState('');
//   const [activeUsers, setActiveUsers] = useState<string[]>([]);
//   const roomName = 'general'; // Example room

//   useEffect(() => {
//     const socket = new ReconnectingWebSocket(`ws://localhost:8000/ws/chat/${roomName}/`);

//     socket.onmessage = (e: MessageEvent) => {
//       const data = JSON.parse(e.data);

//       // Check if the message contains active users
//       if (data.active_users) {
//         setActiveUsers(data.active_users);
//       }

//       // Otherwise, handle chat messages
//       if (data.message && data.username) {
//         setMessages(prevMessages => [...prevMessages, { username: data.username, message: data.message }]);
//       }
//     };

//     return () => {
//       socket.close();
//     };
//   }, []);

//   const sendMessage = () => {
//     const socket = new ReconnectingWebSocket(`ws://localhost:8000/ws/chat/${roomName}/`);
//     socket.send(JSON.stringify({ message: input }));
//     setInput('');
//   };

//   return (
//     <div>
//       <div>
//         <h4>Available Users:</h4>
//         <ul>
//           {activeUsers.map((user, index) => (
//             <li key={index}>{user}</li>
//           ))}
//         </ul>
//       </div>
//       <div>
//         {messages.map((message, index) => (
//           <div key={index}>
//             <strong>{message.username}:</strong> {message.message}
//           </div>
//         ))}
//       </div>
//       <input
//         type="text"
//         value={input}
//         onChange={(e) => setInput(e.target.value)}
//         placeholder="Type your message..."
//       />
//       <div className='spacer-10' />
//       <button onClick={sendMessage}>Send</button>
//     </div>
//   );
// };

// export default System;