import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { apiLink } from '../../radApi';
import { IChildPanelInfoProps } from '../../../types/main';

interface UserInfo {
  ip: string;
  browserName: string;
  browserVersion: string;
  platform: string;
  userAgent: string;
  screenWidth: number;
  screenHeight: number;
  timeZone: string;
  location?: { latitude: string; longitude: string };
}

const UserInfo: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {
  const [userInfo, setUserInfo] = useState<Partial<UserInfo>>({});

  useEffect(() => {
    const getUserInfo = async () => {
      // Step 1: Get IP Address from Django Backend
      try {
        const response = await axios.get(apiLink + '/executive/get_client_info/'); // '/api/get-client-info' Ensure your endpoint is correct
        const { ip } = response.data;

        // Step 2: Get Browser Data and Screen Info
        const userAgent = navigator.userAgent;
        const platform = navigator.platform;
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const screenWidth = window.screen.width;
        const screenHeight = window.screen.height;
        const { name: browserName, version: browserVersion } = getBrowserInfo(userAgent);

        // Step 3: Optionally, Get Geolocation (if permission is granted by the user)
        const location = await getGeolocation();

        setUserInfo({
          ip,
          browserName,
          browserVersion,
          platform,
          userAgent,
          screenWidth,
          screenHeight,
          timeZone,
          location
        });
      } catch (error) {
        console.error('Error getting user info:', error);
      }
    };

    getUserInfo();
  }, []);

  // Function to extract browser info
  const getBrowserInfo = (userAgent: string) => {
    let browserName = 'Unknown';
    let browserVersion = 'Unknown';

    if (userAgent.includes("Firefox")) {
      browserName = "Firefox";
      browserVersion = userAgent.match(/Firefox\/([0-9\.]+)/)?.[1] || 'Unknown';
    } else if (userAgent.includes("Chrome")) {
      browserName = "Chrome";
      browserVersion = userAgent.match(/Chrome\/([0-9\.]+)/)?.[1] || 'Unknown';
    } else if (userAgent.includes("Safari")) {
      browserName = "Safari";
      browserVersion = userAgent.match(/Version\/([0-9\.]+)/)?.[1] || 'Unknown';
    }

    return { name: browserName, version: browserVersion };
  };

  // Function to get geolocation
  const getGeolocation = (): Promise<{ latitude: string; longitude: string } | undefined> => {
    return new Promise((resolve) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve({
              latitude: position.coords.latitude.toString(),
              longitude: position.coords.longitude.toString(),
            });
          },
          () => resolve(undefined)
        );
      } else {
        resolve(undefined);
      }
    });
  };

  return (
    <div>
      <h2>User Info:</h2>
      {userInfo.ip && <p>IP Address: {userInfo.ip}</p>}
      {userInfo.browserName && <p>Browser: {userInfo.browserName}</p>}
      {userInfo.browserVersion && <p>Browser Version: {userInfo.browserVersion}</p>}
      {userInfo.platform && <p>Platform: {userInfo.platform}</p>}
      {userInfo.userAgent && <p>User Agent: {userInfo.userAgent}</p>}
      {userInfo.screenWidth && userInfo.screenHeight && <p>Screen: {userInfo.screenWidth}x{userInfo.screenHeight}</p>}
      {userInfo.timeZone && <p>Timezone: {userInfo.timeZone}</p>}
      {userInfo.location && (
        <p>Geolocation: Latitude {userInfo.location.latitude}, Longitude {userInfo.location.longitude}</p>
      )}
    </div>
  );
};

export default UserInfo;


// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { apiLink } from '../../radApi';

// interface UserInfo {
//   ip: string;
//   city: string;
//   region: string;
//   country: string;
//   org: string;
//   location: { latitude: string, longitude: string };
//   browserName: string;
//   browserVersion: string;
//   platform: string;
//   userAgent: string;
//   screenWidth: number;
//   screenHeight: number;
//   timeZone: string;
// }

// const UserInfo: React.FC = () => {
//   const [userInfo, setUserInfo] = useState<Partial<UserInfo>>({});

// //   const response = await axios.post(apiLink + '/executive/file_upload/', formData, {
// //     headers: {
// //       'Content-Type': 'multipart/form-data'
// //     }
// //   });
// //   setUploadStatus(response.data.message);

//   useEffect(() => {
//     const getClientInfo = async () => {
//       try {
//         // Get backend data
//         const response = await axios.get(apiLink + '/executive/client_info/');
//         const { ip, city, region, country, org, location } = response.data;

//         // Get browser data
//         const userAgent = navigator.userAgent;
//         const platform = navigator.platform;
//         const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

//         const screenWidth = window.screen.width;
//         const screenHeight = window.screen.height;

//         const { name: browserName, version: browserVersion } = getBrowserInfo(userAgent);

//         setUserInfo({
//           ip,
//           city,
//           region,
//           country,
//           org,
//           location: location ? { latitude: location.split(',')[0], longitude: location.split(',')[1] } : undefined,
//           browserName,
//           browserVersion,
//           platform,
//           userAgent,
//           screenWidth,
//           screenHeight,
//           timeZone
//         });
//       } catch (error) {
//         console.error("Error fetching client info", error);
//       }
//     };

//     getClientInfo();
//   }, []);

//   const getBrowserInfo = (userAgent: string) => {
//     let browserName = 'Unknown';
//     let browserVersion = 'Unknown';

//     if (userAgent.indexOf("Firefox") > -1) {
//       browserName = "Firefox";
//       browserVersion = userAgent.match(/Firefox\/([0-9\.]+)/)?.[1] || 'Unknown';
//     } else if (userAgent.indexOf("Chrome") > -1) {
//       browserName = "Chrome";
//       browserVersion = userAgent.match(/Chrome\/([0-9\.]+)/)?.[1] || 'Unknown';
//     } else if (userAgent.indexOf("Safari") > -1) {
//       browserName = "Safari";
//       browserVersion = userAgent.match(/Version\/([0-9\.]+)/)?.[1] || 'Unknown';
//     }

//     return { name: browserName, version: browserVersion };
//   };

//   return (
//     <div>
//       <h2>User Info:</h2>
//       {userInfo.ip && <p>IP Address: {userInfo.ip}</p>}
//       {userInfo.city && <p>Location: {userInfo.city}, {userInfo.region}, {userInfo.country}</p>}
//       {userInfo.org && <p>Organization: {userInfo.org}</p>}
//       {userInfo.browserName && <p>Browser: {userInfo.browserName}</p>}
//       {userInfo.browserVersion && <p>Browser Version: {userInfo.browserVersion}</p>}
//       {userInfo.platform && <p>Platform: {userInfo.platform}</p>}
//       {userInfo.userAgent && <p>User Agent: {userInfo.userAgent}</p>}
//       {userInfo.screenWidth && userInfo.screenHeight && <p>Screen: {userInfo.screenWidth}x{userInfo.screenHeight}</p>}
//       {userInfo.timeZone && <p>Timezone: {userInfo.timeZone}</p>}
//       {userInfo.location && (
//         <p>Geolocation: Latitude {userInfo.location.latitude}, Longitude {userInfo.location.longitude}</p>
//       )}
//     </div>
//   );
// };

// export default UserInfo;


// import React, { useEffect, useState } from 'react';
// import { IChildPanelInfoProps } from '../../../types/main';

// const UserInfo: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {
//   const [userInfo, setUserInfo] = useState<any>(null);

//   useEffect(() => {
//     const getUserInfo = async () => {
//       const geolocation = new Promise((resolve, reject) => {
//         if (navigator.geolocation) {
//           navigator.geolocation.getCurrentPosition(position => {
//             resolve({
//               latitude: position.coords.latitude,
//               longitude: position.coords.longitude
//             });
//           }, () => reject(null));
//         } else {
//           reject(null);
//         }
//       });

//       const ipInfo = fetch('https://ipinfo.io/json?token=YOUR_TOKEN')
//         .then(response => response.json())
//         .catch(() => null);

//       const browserInfo = {
//         browserName: navigator.appName,
//         browserVersion: navigator.appVersion,
//         platform: navigator.platform,
//         userAgent: navigator.userAgent,
//         language: navigator.language,
//         screenWidth: window.screen.width,
//         screenHeight: window.screen.height,
//         timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
//       };

//       const [location, ipData] = await Promise.all([geolocation, ipInfo]);

//       setUserInfo({
//         location,
//         ip: ipData?.ip,
//         city: ipData?.city,
//         region: ipData?.region,
//         country: ipData?.country,
//         org: ipData?.org,
//         ...browserInfo
//       });
//     };

//     getUserInfo();
//   }, []);

//   if (!userInfo) {
//     return <div>Loading user info...</div>;
//   }

//   return (
//     <div>
//       <h2>User Info:</h2>
//       <p>IP Address: {userInfo.ip}</p>
//       <p>Location: {userInfo.city}, {userInfo.region}, {userInfo.country}</p>
//       <p>Organization: {userInfo.org}</p>
//       <p>Browser: {userInfo.browserName}</p>
//       <p>Browser Version: {userInfo.browserVersion}</p>
//       <p>Platform: {userInfo.platform}</p>
//       <p>User Agent: {userInfo.userAgent}</p>
//       <p>Screen: {userInfo.screenWidth}x{userInfo.screenHeight}</p>
//       <p>Timezone: {userInfo.timeZone}</p>
//       {userInfo.location && (
//         <p>Geolocation: Latitude {userInfo.location.latitude}, Longitude {userInfo.location.longitude}</p>
//       )}
//     </div>
//   );
// };

// export default UserInfo;
