import React, { useEffect, useRef, useState } from 'react';
import { IChildPanelInfoProps } from "../../../types/main";
import './panel-footer.scss';
import { RightAngleTriangle } from '../../main/RightAngleTriangle';

export const PanelFooter: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {
  const props = { padding: 10, fill: "none", stroke: childPanelInfoState.mainPanelInfo.styles.borderColor, strokeWidth: 1 };
  const leftPanelRef = useRef<HTMLDivElement>(null);
  const rightPanelRef = useRef<HTMLDivElement>(null);
  const [leftDimensions, setLeftDimensions] = useState({ width: 0, height: 0 });
  const [rightDimensions, setRightDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateSize = () => {
      if (leftPanelRef.current) {
        const { width, height } = leftPanelRef.current.getBoundingClientRect();
        setLeftDimensions({
          width: Math.max(0, width - props.padding * 2),
          height: Math.max(0, height - props.padding * 2),
        });
      }
      if (rightPanelRef.current) {
        const { width, height } = rightPanelRef.current.getBoundingClientRect();
        setRightDimensions({
          width: Math.max(0, width - props.padding * 2),
          height: Math.max(0, height - props.padding * 2),
        });
      }
    };

    updateSize();
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, [props.padding]);

  return <div>
    <div className="column">
      <div >
        {childPanelInfoState.mainPanelInfo.browser.isWebSite ?
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "calc(25% - 2px) 50% calc(25% - 2px)",
              gap: "2px",
              width: "100vw",
              height: "140px",
              boxSizing: "border-box",
              overflow: "hidden"
            }}
          >
            {/* <span style={{
              width: `${childPanelInfoState.mainPanelInfo.left.width - 0}px`,
              // backgroundColor: "gray"
            }}>
              <RightAngleTriangle width={childPanelInfoState.mainPanelInfo.left.width - 0}
                height={125} stroke={childPanelInfoState.mainPanelInfo.styles.borderColor} fill="none" rotation="180" strokeWidth={2}
                padding={4} cornerRadius={15} text="This is my text" />
            </span> */}
            {/* Left Panel */}
            <div
              ref={leftPanelRef}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                boxSizing: "border-box"
              }}
            >
              <RightAngleTriangle width={leftDimensions.width + 25}
                height={130} stroke={props.stroke} fill="none" rotation="180" strokeWidth={2}
                padding={2} cornerRadius={15} text="This is my text"  />
                {/* innerComponent={<>{leftDimensions.width}px</>} */}
            </div>


            <div className="overflow-auto section-border animate" style={{
              height: '124px',
              margin: '4px 0 0 0',
              borderColor: childPanelInfoState.mainPanelInfo.styles.borderColor
            }} >

              {/* <div style={{ padding: "10px" }}>
                <br />
                Width:{childPanelInfoState.mainPanelInfo.center.width}
              </div> */}
            </div>

            <div
              ref={rightPanelRef}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                boxSizing: "border-box"
              }}>
              {/* <RightAngleTriangle width={childPanelInfoState.mainPanelInfo.right.width - 0} height={125} stroke={childPanelInfoState.mainPanelInfo.styles.borderColor}
                fill='none'
                rotation="none" strokeWidth={2}
                padding={4} cornerRadius={15} /> */}
              <RightAngleTriangle width={rightDimensions.width + 25}
                height={130}
                stroke={props.stroke} fill="none" rotation="none"
                strokeWidth={2}
                padding={2} cornerRadius={15} />
            </div>
          </div>
          :









          <div className="height-100">


            <div className="overflow-auto section-border animate" style={{
              width: `${childPanelInfoState.mainPanelInfo.center.width - 4}px`,
              borderColor: childPanelInfoState.mainPanelInfo.styles.borderColor,
              backgroundColor: childPanelInfoState.mainPanelInfo.styles.borderColor,
              color: '#000',
              margin: '4px 0 0 0'
            }} >

              <div style={{ padding: "10px" }}>
                Device Width:{childPanelInfoState.mainPanelInfo.center.width}
              </div>
            </div>



            <div style={{
              display: "grid",
              gridTemplateColumns: "calc(25% - 2px) 50% calc(25% - 2px)",
              gap: "2px",
              width: "100vw",
              height: '60px',
              boxSizing: "border-box",
              overflow: "hidden"
            }}>
              <div
                ref={leftPanelRef}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                  boxSizing: "border-box",
                  margin: "-12px 0 0 0"
                }}
              >
                {/* <RightAngleTriangle width={childPanelInfoState.mainPanelInfo.left.width * .25}
                  height={60} stroke={childPanelInfoState.mainPanelInfo.styles.borderColor}
                  fill={childPanelInfoState.mainPanelInfo.styles.borderColor}
                  rotation="180" strokeWidth={2}
                  padding={4} cornerRadius={15} text="This is my text" /> */}
                <RightAngleTriangle width={leftDimensions.width + 25}
                  height={60} stroke={props.stroke} fill={props.stroke} rotation="180" strokeWidth={2}
                  padding={2} cornerRadius={15} text="This is my text" />
                  {/* innerComponent={<>{leftDimensions.width}px</>}  */}
              </div>



              <div className="overflow-auto section-border animate" style={{
                height: '50px',
                margin: '0px 0 0 0',
                borderColor: props.stroke,
                backgroundColor: props.stroke,
                color: '#000'
              }} >

                <div style={{ padding: "10px" }}>
                  {/* Width:{childPanelInfoState.mainPanelInfo.center.width} */}
                </div>
              </div>
              <div
                ref={rightPanelRef}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                  boxSizing: "border-box",
                  margin: "-12px 0 0 0"
                }}>
                {/* <RightAngleTriangle width={childPanelInfoState.mainPanelInfo.right.width * .25} height={60}
                  stroke={childPanelInfoState.mainPanelInfo.styles.borderColor}
                  fill={childPanelInfoState.mainPanelInfo.styles.borderColor} rotation="none" strokeWidth={2}
                  padding={4} cornerRadius={15} /> */}
                <RightAngleTriangle width={rightDimensions.width + 25}
                  height={60}
                  stroke={props.stroke} fill={props.stroke} rotation="none"
                  strokeWidth={2}
                  padding={2} cornerRadius={15} />
              </div>
            </div>




          </div>}

      </div>
    </div>


  </div>
}

{/* <RightAngleTriangle width={childPanelInfoState.mainPanelInfo.left.width} height={125} stroke={childPanelInfoState.mainPanelInfo.styles.borderColor} fill="none" rotation="180" strokeWidth={2}
      padding={4} cornerRadius={5} />
    <div className="overflow-auto section-border animate"
      style={{
        width: `${childPanelInfoState.mainPanelInfo.center.width - 28}px`,
        borderColor: childPanelInfoState.mainPanelInfo.styles.borderColor,
        // width: `${childPanelInfoState.mainPanelInfo.center.width - 35}px`, //31
        // backgroundColor: "gray"
      }}>
    </div>
    <RightAngleTriangle width={childPanelInfoState.mainPanelInfo.right.width} height={125} stroke={childPanelInfoState.mainPanelInfo.styles.borderColor} fill="none" rotation="none" strokeWidth={2}
      padding={4} cornerRadius={5} /> */}


{/* <div dangerouslySetInnerHTML={{ __html: sanitizeHTMLString }} /> */ }