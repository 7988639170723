import React from "react";
import PortfolioOverview from "./PortfolioOverview";
import TransactionHistory from "./TransactionHistory";
import WalletDetails from "./WalletDetails";
import { IChildPanelInfoProps } from "../../../types/main";

const Bi: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {
  return <div>
      <h1>Bitcoin Tracker</h1>
      <PortfolioOverview />
      <TransactionHistory />
      <WalletDetails />
    </div>;
};

export default Bi;



// import React, { useState } from 'react';

// const CreateWallet: React.FC = () => {
//     const [walletAddress, setWalletAddress] = useState('');

//     const handleCreateWallet = async () => {
//         const response = await fetch('/api/wallet/create/', { method: 'POST' });
//         const data = await response.json();
//         setWalletAddress(data.address);
//     };

//     return (
//         <div>
//             <button onClick={handleCreateWallet}>Create Wallet</button>
//             {walletAddress && <p>Wallet Address: {walletAddress}</p>}
//         </div>
//     );
// };

// export default CreateWallet;