import React, { useState } from 'react';
import { useGesture } from '@use-gesture/react';
import './swipe.scss'
import { IChildPanelInfoProps } from '../../../types/main';


const Swipe: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {
  const [swipeInputs, setSwipeInputs] = useState<string[]>([]);
  const [loginAttempt, setLoginAttempt] = useState(false);

  const mapSwipeToDigit = (direction: string): string => {
    switch (direction) {
      case 'up':
        return '1';
      case 'down':
        return '2';
      case 'left':
        return '3';
      case 'right':
        return '4';
      default:
        return '';
    }
  };

  const handleSwipe = (direction: string) => {
    const digit = mapSwipeToDigit(direction);
    if (digit) {
      setSwipeInputs(prev => {
        const updated = [...prev, digit];
        if (updated.length === 4) {
          handleLogin(updated.join(''));
        }
        return updated;
      });
    }
  };

  const handleLogin = (code: string) => {
    // Trigger login logic with the 4-digit code
    console.log('Attempting login with code:', code);
    setLoginAttempt(true);
    // Example: Call login API or navigate to the next screen
  };

  const bind = useGesture({
    onDrag: ({ direction: [xDir, yDir] }) => {
      if (Math.abs(xDir) > Math.abs(yDir)) {
        handleSwipe(xDir > 0 ? 'right' : 'left');
      } else {
        handleSwipe(yDir > 0 ? 'down' : 'up');
      }
    }
  });

  return (
    <div {...bind()} className="swipe-area">
      <h2>Swipe 4 directions to log in</h2>
      <div>
        {swipeInputs.map((input, idx) => (
          <span key={idx}>{input}</span>
        ))}
      </div>
      {loginAttempt && <p>Logging in...</p>}
    </div>
  );
};

export default Swipe;
