import axios from "axios";
import { API_BASE_URL } from "../../usePostManager";

const api = axios.create({
  baseURL: API_BASE_URL+"/e", // Replace with your API's base URL
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;


// api.interceptors.request.use((config) => {
//     const token = localStorage.getItem("authToken"); // Example token storage
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   });