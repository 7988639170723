import { IMoney, IProcessedExpense, ResultByYearMonthItem, TCounterMap, Totals } from "../../types/money";


export const peopleOptions = ['Wil', 'Nic'];
export const peopleLookUp = (index: number) => peopleOptions[index];
export const moneyOptions = ['Half (Other person owes half)', 'All (Other person owes all)', 'None (Just for records)'];
export const moneyOptionsLookUp = (index: number) => moneyOptions[index];


export const getByYear = (moneyList: IMoney[]): Map<number, Map<number, number[]>> => {
  // Initialize a Map to store the results by year and month
  let resultByYearReturn: Map<number, Map<number, number[]>> = new Map();

  for (let i = 0; i < moneyList.length; i++) {
    const published_at = new Date(moneyList[i].created as string);
    const year = published_at.getFullYear();
    const month = published_at.getMonth();
    // console.log('published_at',published_at,year,month);
    // Ensure the Map contains the entry for the year
    if (!resultByYearReturn.has(year)) {
      resultByYearReturn.set(year, new Map());
    }

    // Ensure the Map for the year contains the entry for the month
    const yearMap = resultByYearReturn.get(year)!;
    if (!yearMap.has(month)) {
      yearMap.set(month, []);
    }

    // Push the amount into the array for the month
    const monthArray = yearMap.get(month)!;
    monthArray.push(moneyList[i].amount);
  }

  return resultByYearReturn;
}

export const getDaysInMonth = (year: number, month: number): number => {
  return new Date(year, month + 1, 0).getDate();
};

export const monthNames: string[] = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

export const getMonthName = (monthIndex: number): string => {
  return monthNames[monthIndex];
};

export const calculateResultByYearMonth = (resultByYear: Map<number, Map<number, number[]>>): {averagePerMonth:number,resultByYearMonth:ResultByYearMonthItem[]} => {
  let averagePerYear = 0;
  let totalPerMonth = 0;
  let averagePerDay = 0;
  let countOfOccurrences = 0
  const resultByYearMonth: ResultByYearMonthItem[] = [];
  resultByYear.forEach((yearMap, year) => {
    yearMap.forEach((monthArray, month) => {
      const sumForMonth = monthArray.reduce((partialSum, a) => partialSum + a, 0);
      totalPerMonth = totalPerMonth+sumForMonth;
      countOfOccurrences++;
      const numberOfDaysInMonth = getDaysInMonth(year, month);
      resultByYearMonth.push({
        month: monthNames[month],
        year,
        value: `$${sumForMonth.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}`,
        numberOfDays: numberOfDaysInMonth,
        valuePerDay: `$${(sumForMonth / numberOfDaysInMonth).toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}`,
      });
    });
  });

  // console.log('resultByYearMonth',resultByYear,resultByYearMonth);

  return {averagePerMonth:totalPerMonth/countOfOccurrences,resultByYearMonth:resultByYearMonth};
};




// const processItems = (itemsMap: Map<number, Map<number, number[]>>): Record<string, ProcessedItem> => {
//   const processedItems: Record<string, ProcessedItem> = {};

//   itemsMap.forEach((yearMap) => {
//     yearMap.forEach((monthArray) => {
//       monthArray.forEach((value) => {
//         const newItemName = value.toString().toLowerCase().replace(/ *\([^)]*\) */g, "").trim();

//         if (!processedItems[newItemName]) {
//           processedItems[newItemName] = { totalValue: 0, count: 0 };
//         }

//         processedItems[newItemName].totalValue += value;
//         processedItems[newItemName].count++;
//       });
//     });
//   });

//   return processedItems;
// };


// [
//   'Process items with single value',
//   new Map([[2022, new Map([[1, [100]]])  ]]),
//   new Map([['t1', { totalValue: 100, count: 1 }]]),
// ],

// - Expected  - 3
//     + Received  + 7

//       Map {
//     -   "t1" => Object {
//     -     "count": 2,
//     -     "totalValue": 300,
//     +   "100" => Object {
//     +     "count": 1,
//     +     "totalValue": 100,
//     +   },
//     +   "200" => Object {
//     +     "count": 1,
//     +     "totalValue": 200,
//         },
//       }


//         // HERE WE ARE ORGANIZING FOR THE ORDERED TOTALS TABLE
//         // This section counts totals and sort by totals.  This next for statement is a fast way to total things
//         let counter = [];
//         let counter2 = [];
//         for (let i = 0; i <= this.newArrayOfObjects.length-1; i++) {
//           //get different items 'that money was spent on' and remove anything in (parentheses) and the _space before it
//           let newItem = this.newArrayOfObjects[i].what.toLowerCase();
//           // console.log('newItem 1',newItem);
//           newItem = newItem.replace(/ *\([^)]*\) */g, "");
//           // console.log('newItem 2',newItem);
//           if (counter[newItem]) {
//               counter[newItem] += Number(this.newArrayOfObjects[i].value);
//           } else {
//               counter[newItem] = Number(this.newArrayOfObjects[i].value);
//           }
//           if (counter2[newItem]) {
//               counter2[newItem] += 1;
//           } else {
//               counter2[newItem] = 1;
//           }
//         };

// const mockMoneyList = [
//           { who: 0, what:'t1', option:0, created: '2022-01-01', amount: 100 },
//           { who: 1, what:'t2', option:1, created: '2022-02-01', amount: 200 },
//           { who: 0, what:'t3', option:2, created: '2022-03-01', amount: 300 },
//         ];



export const processMoneyList = (moneyList: IMoney[]) => {
  const countTotalByItem: TCounterMap = {};
  const countOfOccurrences: TCounterMap = {};
  const averageForItem: TCounterMap = {};

  moneyList.forEach(item => {
    let newItem = item.what.toLowerCase().replace(/ *\([^)]*\) */g, "");
    const newAmount = (countTotalByItem[newItem] || 0) + item.amount;
    const newCountOfOccurrences = (countOfOccurrences[newItem] || 0) + 1;
    countTotalByItem[newItem] = newAmount;
    countOfOccurrences[newItem] = newCountOfOccurrences;
    averageForItem[newItem] = newAmount/newCountOfOccurrences;
  });

  // // Sort the items array by the amount property in ascending order
  // const sortedMoneyListAsc = countTotalByItem.slice().sort((a, b) => a- b);
  // // console.log('countTotalByItem',countTotalByItem)

  // Convert to an array of key-value pairs
  const dataArray = Object.entries(countTotalByItem);

  // Sort the array based on the number values in descending order
  dataArray.sort((a, b) => b[1] - a[1]);

  // Convert the sorted array back to a Record<string, number>
  const sortedData: Record<string, number> = Object.fromEntries(dataArray);


  return { sortedData, countTotalByItem, countOfOccurrences, averageForItem };
};

// // This is for getting totals for each type of item
// export const processItems = (itemsMap: Map<number, Map<number, number[]>>): Map<string, ProcessedItem> => {
//   const processedItems = new Map<string, ProcessedItem>();

//   console.log('itemsMap-->', itemsMap);

//   itemsMap.forEach((yearMap) => {
//     yearMap.forEach((monthArray) => {
//       monthArray.forEach((value) => {
//         const newItemName = value.toString().toLowerCase().replace(/ *\([^)]*\) */g, "").trim();

//         if (!processedItems.has(newItemName)) {
//           processedItems.set(newItemName, { totalValue: 0, count: 0 });
//         }

//         const item = processedItems.get(newItemName)!;
//         item.totalValue += value;
//         item.count++;
//         processedItems.set(newItemName, item);
//       });
//     });
//   });

//   return processedItems;
// };



export const processExpenses = (counterTotalByMonth: TCounterMap, countNumberOfOccurrences: TCounterMap, showAll: boolean): IProcessedExpense[] => {
  const keys = Object.keys(counterTotalByMonth);
  const processedExpenses: IProcessedExpense[] = [];

  for (const key of keys) {
    const thisName = key.charAt(0).toUpperCase() + key.slice(1);

    if (showAll || thisName !== 'Allie') {
      const value = counterTotalByMonth[key];
      const avg = value / countNumberOfOccurrences[key];

      processedExpenses.push({
        name: thisName,
        value,
        count: countNumberOfOccurrences[key],
        avg,
        valueString: value.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
        avgString: avg.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
      });
    }
  }

  // Sort the processed expenses by value in descending order
  processedExpenses.sort((a, b) => b.value - a.value);

  return processedExpenses;
}

// "1.11": 0
// ​​
// amount: 2.22
// ​​
// created: "4/21/2024, 10:41:13 AM"
// ​​
// id: "4c6b18e2-1229-486b-86e1-137c860ce456"
// ​​
// index: 0
// ​​
// option: 0
// ​​
// postId: "348"
// ​​
// title: "0-tt-0-2.22"
// ​​
// tt: ""
// ​​
// updated: ""
// ​​
// what: "tt"
// ​​
// who: 0

export const calculateTotalAmountByPerson = (moneyList: IMoney[]) => {
  const totalAmountByPerson: Record<number, number> = {};

  moneyList.forEach((item) => {
    const { who, amount, option } = item;
    if (totalAmountByPerson[who]) {
      if (option === 0) {
        // console.log("moneyList HALF", who, amount, option, item.what, item.created);
        totalAmountByPerson[who] += amount/2;
      } else if (option === 1 || option.toString() === "1") {
        // console.log("moneyList ALL", who, amount, option, item.what, item.created);
        totalAmountByPerson[who] += amount;
      }
    } else {
      if (option === 0) {
        totalAmountByPerson[who] = amount/2;
      } else if (option === 1) {
        totalAmountByPerson[who] = amount;
      }
    }
  });

  const person1Total = totalAmountByPerson[0] || 0; // Default to 0 if data is missing
  const person2Total = totalAmountByPerson[1] || 0;
  let personWhoOwes:number|undefined = undefined;

  const difference = Math.abs(person1Total - person2Total); // Absolute difference

  if (person1Total > person2Total) {
    personWhoOwes = 1;
    // console.log(`Person 1 owes ${difference} more than Person 2.`);
  } else if (person1Total < person2Total) {
    personWhoOwes = 0;
    // console.log(`Person 2 owes ${difference} more than Person 1.`);
  } else {
    console.log('Both people owe the same amount.');
  }


  return {totalAmountByPerson, personWhoOwes, difference};
};

// export interface IMoney {
//   id?: number;
//   who: number;
//   what: string;
//   amount: number;
//   option: number;
//   created?: string;
//   exclude?: boolean;
//   show?: boolean;
// }


export const convertLineToObject = (inputData:string):IMoney[] => {
  const lines = inputData.split('\n');
  const returnArray = [];
  for (let i = 0; i < lines.length; i++) {
    const [who, what, option, amount, created] = lines[i].split('\t');
    const whoValue = who.trim().toLowerCase() === 'wil' ? 0 : 1;
    const optionValue = option.trim().toLowerCase() === 'half' ? 0 : 1;
  
    returnArray.push({
      who: whoValue,
      what,
      amount: parseFloat(amount),
      option: optionValue,
      created
    });
  }
  return returnArray;
}

// export const calculateTotalsByPerson = (moneyList: IMoney[]): Totals => {
//   // const resultForWil = moneyList.filter(person => person.who === 1);
//   // const resultForNic = moneyList.filter(person => person.who === 0);

//   // const resultForWilHalf = resultForWil.filter(person => person.option === 'half');
//   // const resultForNicHalf = resultForNic.filter(person => person.option === 'half');

//   // const resultForWilAll = resultForWil.filter(person => person.option === 'all');
//   // const resultForNicAll = resultForNic.filter(person => person.option === 'all');
  
//   // let totalForNic = 0;
//   // let totalForWil = 0;

//   // for (let i = 0; i < resultForNicHalf.length; i++) {
//   //   totalForNic += Number(resultForNicHalf[i].value) / 2;
//   // }

//   // for (let i = 0; i < resultForNicAll.length; i++) {
//   //   totalForNic += Number(resultForNicAll[i].value);
//   // }

//   // for (let i = 0; i < resultForWilHalf.length; i++) {
//   //   totalForWil += Number(resultForWilHalf[i].value) / 2;
//   // }

//   // for (let i = 0; i < resultForWilAll.length; i++) {
//   //   totalForWil += Number(resultForWilAll[i].value);
//   // }

//   // let totalDif = '';
//   // if (totalForWil > totalForNic) {
//   //   totalDif = `Nic Owes $${(totalForWil - totalForNic).toFixed(2)}`;
//   // } else {
//   //   totalDif = `Will Owes $${(totalForNic - totalForWil).toFixed(2)}`;
//   // }

//   // return { totalForWil, totalForNic, totalDif };
//   return { totalForWil: 0, totalForNic: 0, totalDif: '' };
// }
 

// interface Props {
//   counter: Record<string, number>;
//   counter2: Record<string, number>;
//   showAll: boolean;
// }

// export const ProcessExpenses: React.FC<Props> = ({ counter, counter2, showAll }) => {
//   const localTotalAndSortedExpenses: ExpenseItem[] = [];

//   const keys = Object.keys(counter);

//   keys.forEach((key) => {
//     const thisName = key.charAt(0).toUpperCase() + key.slice(1);
//     if (showAll || thisName !== 'Allie') {
//       const value = counter[key];
//       const avg = value / counter2[key];

//       localTotalAndSortedExpenses.push({
//         name: thisName,
//         value: value,
//         count: counter2[key],
//         avg: avg,
//         valueString: value.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ","),
//         avgString: avg.toFixed(2).toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
//       });
//     }
//   });

//   // Sort expenses by value in descending order
//   localTotalAndSortedExpenses.sort((a, b) => b.value - a.value);

//   // return (
//   //   <div>
//   //     {localTotalAndSortedExpenses.map((expense, index) => (
//   //       <div key={index}>
//   //         <span>{expense.name}</span>
//   //         <span>{expense.valueString}</span>
//   //         <span>{expense.count}</span >
//   //         <span>{expense.avgString}</span>
//   //       </div>
//   //     ))}
//   //   </div>
//   // );
// };

