import React, { useEffect, useRef, useState } from "react";
import { MySVGComponentProps } from "../../types/main";
import { IChildPanelInfoWithResizeProps } from "../panels/panel-top/PanelTop";
import BlinkingCircles from "../panels/panel-top/blinking-circles/BlinkingCircles";

export const RightAngleTriangle: React.FC<MySVGComponentProps> = ({
    width = 50,
    height = 50,
    stroke = '#000000',
    fill = '#000000',
    strokeWidth = 2,
    rotation = 'none',
    padding = 0,
    cornerRadius = 10,
    innerComponent,
  }) => {
    const points = () => {
      switch (rotation) {
        case '90':
          return `${width - padding},${height - padding} ${padding},${height - padding} ${padding},${padding}`;
        case '180':
          return `${width - padding},${height - padding} ${width - padding},${padding} ${padding},${padding}`;
        case '270':
          return `${padding},${height - padding} ${width - padding},${padding} ${width - padding},${height - padding}`;
        default: // 'none' or any other value
          return `${padding},${padding} ${width - padding},${padding} ${padding},${height - padding}`;
      }
    };
    // const points = () => {
    //   switch (rotation) {
    //     case '90':
    //       return `${width + padding},${height + padding} ${padding},${height + padding} ${padding},0`;
    //     case '180':
    //       return `${width + padding},${height + padding} ${width + padding},${padding} ${padding},${height + padding}`;
    //     case '270':
    //       return `${padding},${height + padding} ${width + padding},0 ${width + padding},${height + padding}`;
    //     default: // 'none' or any other value
    //       return `${padding},${height + padding} ${width + padding},${height + padding} ${padding},0`;
    //   }
    // };
  
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${width + padding} ${height + padding}`}
        width={width + padding}
        height={height + padding}
      >
        <polygon
          points={points()}
          fill={fill}
          stroke={stroke}
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          strokeLinejoin="round"
          rx={cornerRadius}
          ry={cornerRadius}
        />
        {innerComponent && (
          <foreignObject width={width} height={height} x={padding} y={padding}>
            {innerComponent}
          </foreignObject>
        )}
      </svg>
    );
  };
  
  export const MySVGComponent:React.FC<IChildPanelInfoWithResizeProps> = ({ childPanelInfoState, setChildPanelInfoState, handleResize }) => {
    const props = { padding: 10, fill:"none", stroke:childPanelInfoState.mainPanelInfo.styles.borderColor, strokeWidth:1 };
    const leftPanelRef = useRef<HTMLDivElement>(null);
    const rightPanelRef = useRef<HTMLDivElement>(null);
    const [leftDimensions, setLeftDimensions] = useState({ width: 0, height: 0 });
    const [rightDimensions, setRightDimensions] = useState({ width: 0, height: 0 });
  
    useEffect(() => {
      const updateSize = () => {
        if (leftPanelRef.current) {
          const { width, height } = leftPanelRef.current.getBoundingClientRect();
          setLeftDimensions({
            width: Math.max(0, width - props.padding * 2),
            height: Math.max(0, height - props.padding * 2),
          });
        }
        if (rightPanelRef.current) {
          const { width, height } = rightPanelRef.current.getBoundingClientRect();
          setRightDimensions({
            width: Math.max(0, width - props.padding * 2),
            height: Math.max(0, height - props.padding * 2),
          });
        }
      };
  
      updateSize();
      window.addEventListener("resize", updateSize);
      return () => window.removeEventListener("resize", updateSize);
    }, [props.padding]);
  
    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "calc(25% - 2px) 50% calc(25% - 2px)",
          gap: "2px",
          width: "100vw",
          height: "140px",
          boxSizing: "border-box",
          overflow: "hidden"
        }}
      >
        {/* Left Panel */}
        <div
          ref={leftPanelRef}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            boxSizing: "border-box"
          }}
        >
          <RightAngleTriangle width={leftDimensions.width + 25}
            height={130} stroke={props.stroke} fill="none" rotation="270" strokeWidth={2}
            padding={2} cornerRadius={15} text="This is my text" innerComponent={<>{leftDimensions.width}px</>} />
        </div>
  
        {/* Center Panel */}
        <div
          className="overflow-auto section-border animate" style={{
            width: `${childPanelInfoState.mainPanelInfo.center.width-5}px`, //- 20
            height: '124px',
            margin: '4px 0 0 0',
            borderColor: childPanelInfoState.mainPanelInfo.styles.borderColor
          }}
        ></div>
  
        {/* Right Panel */}
        <div
          ref={rightPanelRef}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            overflow: "hidden",
            boxSizing: "border-box"
          }}
        >
          <RightAngleTriangle width={rightDimensions.width + 25}
            height={130}
            stroke={props.stroke} fill="none" rotation="90"
            strokeWidth={2}
            padding={2} cornerRadius={15} innerComponent={<BlinkingCircles isWebSite={true} numberOfCircles={5} />} />
        </div>
      </div>
    );
  };
  




// export const RightAngleTriangle: React.FC<MySVGComponentProps> = ({
//   width = 50,
//   height = 50,
//   stroke = '#000000',
//   fill = '#000000',
//   strokeWidth = 1,
//   rotation = 'none',
//   padding = 0,
//   cornerRadius = 0,
//   innerComponent,
// }) => {
//   const points = () => {
//     switch (rotation) {
//       case '90':
//         return `${width},${height} 0,${height} 0,0`;
//       case '180':
//         return `${width},${height} ${width},0 0,${height}`;
//       case '270':
//         return `0,${height} ${width},0 ${width},${height}`;
//       default: // 'none' or any other value
//         return `0,0 ${width},0 0,${height}`;
//     }
//   };

//   return (
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       viewBox={`0 0 ${width} ${height}`}
//       width={width}
//       height={height}
//     >
//       <polygon
//         points={points()}
//         fill={fill}
//         stroke={stroke}
//         strokeWidth={strokeWidth}
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         rx={cornerRadius}
//         ry={cornerRadius}
//       />
//       {innerComponent && (
//         <foreignObject width={width} height={height}>
//           {innerComponent}
//         </foreignObject>
//       )}
//     </svg>
//   );
// };

  
  // export const RightAngleTriangle: React.FC<MySVGComponentProps> = ({
  //   width = 50,
  //   height = 50,
  //   stroke = '#000000',
  //   fill = '#000000',
  //   strokeWidth = 1,
  //   rotation = 'none',
  //   padding = 0,
  //   cornerRadius = 0,
  //   innerComponent,
  // }) => {
  //   const points = () => {
  //     switch (rotation) {
  //       case '90':
  //         return `${width + padding},${height + padding} ${padding},0 ${padding},${height + padding}`;
  //       case '180':
  //         return `${width + padding},${height + padding} 0,${padding} ${width + padding},${padding}`;
  //       case '270':
  //         return `0,${height + padding} ${width + padding},${height + padding} ${width + padding},${padding}`;
  //       default:
  //         return `0,0 ${width + padding},0 0,${height + padding}`;
  //     }
  //   };
  
  //   return (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       viewBox={`0 0 ${width + padding} ${height + padding}`}
  //       width={width}
  //       height={height}
  //     >
  //       <polygon
  //         points={points()}
  //         fill={fill}
  //         stroke={stroke}
  //         strokeWidth={strokeWidth}
  //         strokeLinecap="round"
  //         strokeLinejoin="round"
  //       // rx={cornerRadius}
  //       // ry={cornerRadius}
  //       />
  //       {innerComponent && (
  //         <foreignObject width={width} height={height} x={padding} y={padding}>
  //           {innerComponent}
  //         </foreignObject>
  //       )}
  //     </svg>
  //   );
  // };