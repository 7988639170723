import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import './invoice-generator.scss';
import axios from 'axios';
import { getCsrfToken } from '../../../apolloClient';
import { IInvoice } from './Invoice';

interface IInvoiceGeneratorProps {
  invoiceData: IInvoice;
  // addInvoiceToSystem: (formData: IInvoice) => Promise<void>;
  onSave: (title: string, newEntry: any) => void;
  setSelectedData?: Dispatch<SetStateAction<IInvoice | undefined>>;
}

export const InvoiceGenerator: React.FC<IInvoiceGeneratorProps> = ({ invoiceData, onSave, setSelectedData }) => {
  // const [formData, setFormData] = useState<IInvoice>(invoiceData as IInvoice);

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    console.log({ name, value });
    if (setSelectedData !== undefined) {
      setSelectedData({
        ...invoiceData,
        [name]: value,
      });
    }
    console.log(name,value);
  };

  const handleItemChange = (e: any, index: number, name: string) => {
    const { value } = e.target;
    const updatedItems = [...invoiceData.items];
    //{name: '', quantity: 1, unit_cost: 0, items[0][quantity]: '3', items[0][name]: 'sdf'}
    // updatedItems
    updatedItems[index] = {
      ...updatedItems[index],
      [name]: value,
    };
    if (setSelectedData !== undefined) {
    setSelectedData({
      ...invoiceData,
      items: updatedItems,
    });
  }
  };

  const addItem = () => {
    if (setSelectedData !== undefined) {
        setSelectedData({
        ...invoiceData,
        items: [
          ...invoiceData.items,
          {
            name: '',
            quantity: 1,
            unit_cost: 0,
          },
        ],
      });
    }};

  const handleSubmit = (e: any) => {
    e.preventDefault();
    onSave(invoiceData.to,invoiceData);
  };

  // useEffect(() => {
  //   setSelectedData(invoiceData);
  // }, [invoiceData]);
  
  useEffect(() => {
    console.log('invoiceData', invoiceData);
  }, [invoiceData]);

  return (
    <form onSubmit={handleSubmit} >
      <div className="invoice-form">
        <div className="address-section">
          <div>
            <label className="form-label">From:</label>
            <input
              type="text"
              name="from"
              defaultValue={invoiceData.from}
              onChange={handleInputChange}
              className="form-input"
            />
            <input
              type="text"
              name="fromAddressLineOne"
              defaultValue={invoiceData.fromAddressLineOne}
              onChange={handleInputChange}
              className="form-input"
            />
            <input
              type="text"
              name="fromAddressLineTwo"
              defaultValue={invoiceData.fromAddressLineTwo}
              onChange={handleInputChange}
              className="form-input"
            />
          </div>
          <div>
            <label className="form-label">To:</label>
            <input
              type="text"
              name="to"
              defaultValue={invoiceData.to}
              onChange={handleInputChange}
              className="form-input"
            />
            <input
              type="text"
              name="toAddressLineOne"
              defaultValue={invoiceData.toAddressLineOne}
              onChange={handleInputChange}
              className="form-input"
            />
            <input
              type="text"
              name="toAddressLineTwo"
              defaultValue={invoiceData.toAddressLineTwo}
              onChange={handleInputChange}
              className="form-input"
            />
          </div>
        </div>
        
        <div className="form-section">
          {/* Add more fields for logo, number, date, due_date, etc. */}
        </div>
        <div className="form-section">
          <label className="form-label">Items:</label>
          {invoiceData.items.map((item, index) => (
            <div key={index} className="item-container">
              <input
                type="text"
                name={`items[${index}][name]`}
                defaultValue={item.name}
                onChange={(e) => handleItemChange(e, index, 'name')}
                className="form-input"
                placeholder="Item Name"
              />
              <input
                type="number"
                name={`items[${index}][quantity]`}
                defaultValue={item.quantity}
                onChange={(e) => handleItemChange(e, index, 'quantity')}
                className="quantity-input"
                placeholder="Quantity"
              />
              <input
                type="number"
                name={`items[${index}][unit_cost]`}
                defaultValue={item.unit_cost}
                onChange={(e) => handleItemChange(e, index, 'unit_cost')}
                className="cost-input"
                placeholder="Unit Cost"
              />
            </div>
          ))}
          <div className="spacer-10" />
          <button type="button" onClick={addItem} className="add-item-button" title="Add Item">
            Add Item
          </button>
        </div>
        <div>
          <label>Taxes</label>
          <input
            name="taxPercent"
            type="number"
            defaultValue={invoiceData.taxPercent}
            onChange={handleInputChange}
          />
        </div>
        <div className="form-section">
          <label className="form-label">Notes:</label>
          <textarea
            name="notes"
            defaultValue={invoiceData.notes}
            onChange={handleInputChange}
            className="form-textarea"
            placeholder="Additional Notes"
          />
        </div>
        <div className="form-section">
          <label className="form-label">Terms:</label>
          <input
            type="text"
            name="terms"
            defaultValue={invoiceData.terms}
            onChange={handleInputChange}
            className="form-input"
            placeholder="Payment Terms"
          />
        </div>
      </div>
      <div className='spacer-10' />
      <button type="submit" className="submit-button" title='Generate Invoice'>
        Generate Invoice
      </button>
    </form>
  );
};




  // const CREATE_POST = `mutation CreatePost($input: PostInput!) {
  //     createPost(input: $input) {
  //       post {
  //         id
  //         title
  //         # Include other fields you want in the response
  //       }
  //     }
  //   }`;

  // const CREATE_POST = `mutation {
  //       createPost(input: {
  //         tags: "some tags",
  //         html: "some html",
  //         by: "some user",
  //         status: "some status",
  //         title: "some title",
  //         featured: true,
  //         data: "{\"key\": \"value\"}"
  //       }) {
  //         post {
  //           id
  //           title
  //         }
  //       }
  //     }`;

  

// const createPDF = async () => {
//   try {
//     // Send a POST request to generate the PDF
//     const response = await axios.post('https://invoice-generator.com/api/v1/invoices', formData, {
//       responseType: 'blob', // Receive the response as a binary blob
//     });

//     // Create a blob URL for the PDF content
//     const blob = new Blob([response.data], { type: 'application/pdf' });
//     const pdfUrl = URL.createObjectURL(blob);

//     // Open the generated PDF in a new tab
//     window.open(pdfUrl);
//   } catch (error) {
//     console.error('Error generating PDF:', error);
//   }
// };



// // // Your form data
      // const postData2 = {
      //     tags: [formData.to, 'invoice'].toString(),
      //     html: '',
      //     by: '0',
      //     status: 'active',
      //     title: `${formData.to} ${formData.date}`,
      //     featured: true,
      //     data: JSON.stringify(formData),//'{"key": "value"}', // JSON string
      // };

      // mutation CreatePost($input: PostInput!) {
      //     createPost(input: $input) {
      //       post {
      //         id
      //         title
      //         # Include other fields you want in the response
      //       }
      //     }
      //   }

      // mutation CreatePost($tags: [ID], $html: String!, $by: ID!, $status: ID!, $title: String!, $featured: Boolean!, $data: JSONString) {
      //     createPost(input: { tags: $tags, html: $html, by: $by, status: $status, title: $title, featured: $featured, data: $data }) {
      //       post {
      //         id
      //         title
      //         // Include other fields you want in the response
      //       }
      //     }
      //   }


      // mutation {
      //     createPost(input: {
      //       tags: [1, 2],
      //       html: "Some HTML content",
      //       by: 1,
      //       status: 1,
      //       title: "Post Title",
      //       featured: true,
      //       data: "{}"
      //     }) {
      //       post {
      //         id
      //         title
      //         # Include other fields you want to retrieve
      //       }
      //     }
      //   }

      //     const mutation = `
      //     mutation {
      //       createPost(
      //         tags: ${JSON.stringify(formData.tags)},
      //         html: "${formData.html}",
      //         by: "${formData.by}",
      //         status: "${formData.status}",
      //         title: "${formData.title}",
      //         featured: ${formData.featured},
      //         data: ${formData.data}
      //       ) {
      //         post {
      //           id
      //           title
      //           // Add other fields you want to retrieve
      //         }
      //       }
      //     }
      //   `;


      // const postData2 = {
      //     html: '<p>test</p>',
      //     by: 1, // Replace with the actual user ID (number)
      //     status: 1, // Replace with the actual status ID (number)
      //     title: `${formData.to} ${formData.date}`,
      //     featured: true,
      //     tags: [1], // Replace with actual tag IDs (numbers)
      //     data: JSON.stringify(formData), // Add the necessary data
      // };

      

//   `mutation CreateInvoice($input: InvoiceInput!) {
  //     createInvoice(input: {
  //       data: {
  //         from: "Nikolaus Ltd",
  //         to: "Acme, Corp.",
  //         logo: "https://example.com/img/logo-invoice.png",
  //         number: 1,
  //         date: "Feb 9, 2015",
  //         due_date: "Feb 16, 2015",
  //         items: [...], // your items array
  //         notes: "Thanks for being an awesome customer!",
  //         terms: "Please pay by the due date."
  //       },
  //       /* other fields */
  //     }) {
  //       // your response fields
  //     }
  //   }`

  // const addInvoiceToSystem = async () => {

  //     console.log('Form Data', formData);

  //     // const { from, to, logo, number, date, due_date, items, notes, terms } = formData;

  //     postData.status = 'published';
  //     postData.title = `${formData.from}-${formData.to}-${formData.number}`;
  //     // postData.html = JSON.stringify({
  //     //     from: formData.from,
  //     //     to: formData.to,
  //     //     logo: formData.logo,
  //     //     number: formData.number,
  //     //     date: formData.date,
  //     //     due_date: formData.due_date,
  //     //     items: formData.items,
  //     //     notes: formData.notes,
  //     //     terms: formData.terms,
  //     // });
  //     postData.data = {
  //         from: formData.from,
  //         to: formData.to,
  //         logo: formData.logo,
  //         number: formData.number,
  //         date: formData.date,
  //         due_date: formData.due_date,
  //         items: formData.items,
  //         notes: formData.notes,
  //         terms: formData.terms,
  //     };
  //     postData.tags = [formData.from, 'invoice'].toString();
  //     postData.featured = true;


  //     // // Assuming this.appService.addObject is an HTTP service for making API calls
  //     // apiService.addObject('invoice/generate', info).then((res: any) => {
  //     //     console.log('Return from addMoney', res);
  //     //     // You might want to use state to trigger a re-render or perform other actions here
  //     // });
  //     // };



  //     // const handlePostInvoice = async () => {
  //     try {
  //         // Fetch the CSRF token
  //         const csrfToken = await getCsrfToken();

  //         //   postData.data = 

  //         //   setPostData()

  //         // Prepare the request options
  //         const requestOptions = {
  //             method: 'POST',
  //             headers: {
  //                 'Content-Type': 'application/json',
  //                 'X-CSRFToken': csrfToken || '',
  //             },
  //             body: JSON.stringify({
  //                 query: `
  //             ${CREATE_POST}
  //           `,
  //                 variables: {
  //                     input: postData,
  //                 },
  //             }),
  //             credentials: 'include' as RequestCredentials,
  //         };

  //         // Make the request
  //         const response = await fetch('${apiLink}/graphql/', requestOptions);
  //         const responseData = await response.json();

  //         // Handle the response data as needed
  //         console.log('Response:', responseData.data.getPosts);

  //         //   setPostsDataState(responseData.data.getPosts)
  //     } catch (error) {
  //         // Handle any errors that may occur during the mutation
  //         console.error('Error creating post:', error);
  //     }
  // };



// import React, { useState } from 'react';
// import { useMutation } from '@apollo/client';
// import { CREATE_POST } from 'path/to/your/graphql/mutations'; // Import your GraphQL mutation

// const CreatePostForm: React.FC = () => {
//   const [html, setHtml] = useState<string>('');
//   const [title, setTitle] = useState<string>('');
//   const [featured, setFeatured] = useState<boolean>(false);
//   const [tags, setTags] = useState<string[]>([]);

//   // Add other necessary state variables

//   const [createPost, { loading, error }] = useMutation(CREATE_POST);

//   const handleCreatePost = async () => {
//     try {
//       const result = await createPost({
//         variables: {
//           html,
//           by: 'USER_ID', // Replace with the actual user ID
//           status: 'STATUS_ID', // Replace with the actual status ID
//           title,
//           featured,
//           tags,
//           data: JSON.stringify({}), // Add the necessary data
//         },
//       });

//       // Handle success, e.g., show a success message or redirect
//       console.log('Post created:', result.data.createPost.post);
//     } catch (err) {
//       // Handle error, e.g., show an error message
//       console.error('Error creating post:', err.message);
//     }
//   };

//   return (
//     <div>
//       <h2>Create a New Post</h2>
//       <form>
//         {/* Add your form fields for html, title, featured, tags, and other necessary fields */}
//         <label>
//           HTML:
//           <textarea value={html} onChange={(e) => setHtml(e.target.value)} />
//         </label>
//         <br />
//         <label>
//           Title:
//           <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
//         </label>
//         <br />
//         <label>
//           Featured:
//           <input type="checkbox" checked={featured} onChange={() => setFeatured(!featured)} />
//         </label>
//         <br />
//         <label>
//           Tags:
//           <input
//             type="text"
//             value={tags.join(',')}
//             onChange={(e) => setTags(e.target.value.split(','))}
//           />
//         </label>
//         <br />
//         {/* Add other form fields */}
//         <button type="button" onClick={handleCreatePost} disabled={loading}>
//           Create Post
//         </button>
//       </form>
//       {loading && <p>Loading...</p>}
//       {error && <p>Error: {error.message}</p>}
//     </div>
//   );
// };

// export default CreatePostForm;




// import React from 'react';
// import { useMutation } from '@apollo/client';
// import { gql } from 'graphql-tag';

// const CREATE_POST = gql`
//   mutation CreatePost($input: PostInput!) {
//     createPost(input: $input) {
//       post {
//         id
//         title
//         # Include other fields you want in the response
//       }
//     }
//   }
// `;

// const YourComponent: React.FC = () => {
//   // Initialize the useMutation hook
//   const [createPost, { loading, error }] = useMutation(CREATE_POST);

//   // Define a function to handle the form submission
//   const handleCreatePost = async () => {
//     try {
//       // Your form data
//       const postData = {
//         tags: 'some tags',
//         html: 'some html',
//         by: 'some user',
//         status: 'some status',
//         title: 'some title',
//         featured: true,
//         data: '{"key": "value"}', // JSON string
//       };

//       // Call the mutation
//       const { data } = await createPost({
//         variables: {
//           input: postData,
//         },
//       });

//       // Handle the response data as needed
//       console.log('Response:', data);
//     } catch (error) {
//       // Handle any errors that may occur during the mutation
//       console.error('Error creating post:', error.message);
//     }
//   };

//   return (
//     <div>
//       {/* Your component JSX */}
//       <button onClick={handleCreatePost} disabled={loading}>
//         Create Post
//       </button>
//       {loading && <p>Loading...</p>}
//       {error && <p>Error: {error.message}</p>}
//     </div>
//   );
// };

// export default YourComponent;
