import { ApolloClient, InMemoryCache } from '@apollo/client';
import { apiLink } from './components/radApi';

export const client = new ApolloClient({
  
  uri: `${apiLink}/graphql/`, // Update with your GraphQL server endpoint
  // uri: 'http://localhost:8000/graphql/', // Update with your GraphQL server endpoint
  cache: new InMemoryCache(),
});

export const getCsrfToken = async (): Promise<string> => {
  const response = await fetch(`${apiLink}/get-csrf-token/`);
  // const response = await fetch('http://localhost:8000/get-csrf-token/');
  const data = await response.json();
  return data.csrfToken;
};
