import React, { useState, useEffect } from 'react';
import "./blinking-circles.scss";
import circles from './Circles';

export interface IBlinkingCirclesProps {
  isWebSite: boolean;
  numberOfCircles: number;
}

const BlinkingCircles: React.FC<IBlinkingCirclesProps> = ({ isWebSite, numberOfCircles }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [circles, setCircles] = useState<Array<{ id: number; isVisible: boolean }>>(() => {
    let returnArray = [];
    for (let i = 0; i < numberOfCircles; i++)
      returnArray.push({ id: i, isVisible: false });
    return returnArray;
  });


  const handleToggleFullScreen = () => {
    const elem = document.documentElement;

    if (!isFullScreen) {
      // Enter full screen
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if ((elem as any)['mozRequestFullScreen']) {
        (elem as any)['mozRequestFullScreen']();
      } else if ((elem as any)['webkitRequestFullscreen']) {
        (elem as any)['webkitRequestFullscreen']();
      } else if ((elem as any)['msRequestFullscreen']) {
        (elem as any)['msRequestFullscreen']();
      }
    } else {
      // Exit full screen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if ((document as any)['webkitExitFullscreen']) {
        (document as any)['webkitExitFullscreen']();
      } else if ((document as any).mozCancelFullScreen) {
        (document as any).mozCancelFullScreen();
      } else if ((document as any)['msExitFullscreen']) {
        (document as any)['msExitFullscreen']();
      }
    }

    // Toggle the state
    setIsFullScreen(!isFullScreen);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Randomly select a circle to toggle visibility
      const randomIndex = Math.floor(Math.random() * circles.length);
      const updatedCircles = circles.map(circle =>
        circle.id === circles[randomIndex].id
          ? { ...circle, isVisible: !circle.isVisible }
          : circle
      );
      setCircles(updatedCircles);
    }, 300); // Blink every 1000 milliseconds (1 second)

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [circles]);

  return (
    <span className='blinking-circles'>

      <br />
      <span className='button-group'>
        {isWebSite && <button onClick={handleToggleFullScreen} />}
        <span className='width-spacer-10' />
        <circle />
        {/* <span className='circles'>
          {circles.map(circle => (
            <span
              key={circle.id}
              style={{
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                backgroundColor: 'red',
                margin: '5px',
                display: circle.isVisible ? 'inline-block' : 'none',
              }}
            ></span>
          ))}
        </span> */}
        {/* <button onClick={handleToggleFullScreen} />
        <span className='width-spacer-10' /> */}
      </span>
    </span >
    // <span className='blinking-circles'>
    //   {circles.map(circle => (
    //     <span
    //       key={circle.id}
    //       style={{
    //         width: '10px',
    //         height: '10px',
    //         borderRadius: '50%',
    //         backgroundColor: 'red',
    //         margin: '5px',
    //         display: circle.isVisible ? 'inline-block' : 'none',
    //       }}
    //     ></span>
    //   ))}
    //   <br />
    //   <span className='button-group'>
    //     <button onClick={handleToggleFullScreen} />
    //     <span className='width-spacer-10' />
    //     <button onClick={handleToggleFullScreen} />
    //     <span className='width-spacer-10' />
    //   </span>
    // </span >
  );
};

export default BlinkingCircles;
