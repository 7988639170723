import React, { useEffect, useRef, useState } from 'react';
import { IChildPanelInfoProps } from "../../../types/main"
import { AnalogClock } from "./clocks/AnalogClock"
import { RoundClock } from "./clocks/RoundClock"
import DateTime from "./clocks/date-time/DateTime"
import Todos from "./todos/Todos"

export const PanelLeft: React.FC<IChildPanelInfoProps> = ({ childPanelInfoState, setChildPanelInfoState }) => {
  // const [width, setWidth] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  // useEffect(() => {
  //   const observer = new ResizeObserver(([entry]) => {
  //     setWidth(entry.contentRect.width);
  //   });

  //   if (ref.current) {
  //     observer.observe(ref.current);
  //   }

  //   return () => {
  //     observer.disconnect();
  //   };
  // }, []);
  return <>
    {/* <div className="width-100-percent">
            <div className={`clock-container ${selectedColor}`}>
                <div className="time">{date}</div>
                <div className="date">{dateString}</div>
            </div>
            <div className="white-font money-wrapper">
                <div>
                    <span className="wod" onClick={getNewWOD}>{WOD}</span>
                </div>
                <div>
                    <span className="wod" onClick={getNewMathProblem}>{mathProblem}</span>
                </div>
            </div>
            <Todos />
        </div> */}
    <div ref={ref}>
      {/* {width}<br /> */}
      {/* <AnalogClock /> */}
      <DateTime />
      <div className="spacer-10"></div>
      <Todos childPanelInfoState={childPanelInfoState} setChildPanelInfoState={setChildPanelInfoState} />
    </div>
  </>
}