import { useState, useEffect } from "react";
import api from "./api";
import { BitcoinHolding, BitcoinTransaction, Wallet } from "./types";

export const useBitcoinHolding = () => {
  const [holding, setHolding] = useState<BitcoinHolding | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchHolding = async () => {
      try {
        const response = await api.get<BitcoinHolding>("/holdings/");
        setHolding(response.data);
      } catch (error) {
        console.error("Error fetching holdings:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchHolding();
  }, []);

  return { holding, loading };
};

export const useTransactions = () => {
  const [transactions, setTransactions] = useState<BitcoinTransaction[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        // Directly set the data from the API as an array
        const response = await api.get<BitcoinTransaction[]>("/transactions/");
        setTransactions(response.data); // No "transactions" property assumed
      } catch (error) {
        console.error("Error fetching transactions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, []);

  return { transactions, loading };
};

// export const useTransactions = () => {
//   const [transactions, setTransactions] = useState<BitcoinTransaction[]>([]);
//   const [loading, setLoading] = useState<boolean>(true);

//   useEffect(() => {
//     const fetchTransactions = async () => {
//       try {
//         const response = await api.get<BitcoinTransaction[]>("/transactions/");
//         setTransactions(response.data.transactions);
//       } catch (error) {
//         console.error("Error fetching transactions:", error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchTransactions();
//   }, []);

//   return { transactions, loading };
// };

export const useWallet = () => {
  const [wallet, setWallet] = useState<Wallet | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchWallet = async () => {
      try {
        const response = await api.get<Wallet>("/wallet/");
        setWallet(response.data);
      } catch (error) {
        console.error("Error fetching wallet:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchWallet();
  }, []);

  return { wallet, loading };
};
