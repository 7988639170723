import React from 'react';
import { useEffect, useState } from 'react';
import ApiService from '../../serviceApi';
import './add-money.scss';
import { IMoney } from '../../../types/money';
import { moneyOptions, moneyOptionsLookUp, peopleLookUp, peopleOptions } from '../../helpers/moneyHelpers';
import { ISelectedItem } from './Money';

interface IMoneyProps {
  onAdd: (title: string, newEntry: IMoney) => void;
  selectedItem: ISelectedItem|undefined;
}

export const AddMoney: React.FC<IMoneyProps> = ({onAdd, selectedItem}) => {
  const apiService = new ApiService();
  const [formData, setFormData] = useState<IMoney>({
    who: 0,
    what: '',
    amount: 0,
    option: 0,
  });
  const [people, setPeople] = useState<string[]>(peopleOptions); // Replace with your list of people
  const [options, setOptions] = useState<string[]>(moneyOptions); // Replace with your list of options

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const addMoney = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();


    const { who, what, amount, option } = formData;
    const howMuch = Number(amount.toString().split(',').join('')).toFixed(2).toString();
    const updatedFormData = {...formData, amount:Number(amount.toString().replace(/\$|,/g, ''))};
    // console.log("updatedFormData 1",updatedFormData);
    updatedFormData.option=parseInt(updatedFormData.option.toString());
    updatedFormData.who=parseInt(updatedFormData.who.toString());
    // console.log("updatedFormData 2",updatedFormData);
    onAdd(`${peopleLookUp(who)}-${what}-${moneyOptionsLookUp(option)}-${howMuch}`,updatedFormData);

    setFormData(prevState => ({
      ...prevState,
      what: "",
      amount: 0,
    }));
  };

  useEffect(()=>{
    if (selectedItem!== undefined) {
      console.log('win selectedItem',selectedItem);
      setFormData(prevState=>({
        ...prevState,
        what: selectedItem.itemSelected,
        amount: selectedItem.averageSpent
      }))
    }
  },[selectedItem])

  return (
    <form onSubmit={addMoney}>
      <div>
        <table className='table-100'>
          {/* <thead>
            <tr>
              <th>Person</th>
              <th>What</th>
              <th>Option</th>
              <th>Amount</th>
            </tr>
          </thead> */}
          <tbody>
            <tr className='no-padding'>
              <td className='column-who'>
                <select name="who" value={formData.who} onChange={handleChange}>
                  {people.map((person, index) => (
                    <option key={index} value={index}>
                      {person}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <input
                  type="text"
                  name="what"
                  value={formData.what}
                  onChange={handleChange}
                />
              </td>
              <td className='column-option'>
                {/* <select name="option" value={formData.option} onChange={handleChange}>
                  {options.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select> */}
                <select name="option" value={formData.option} onChange={handleChange}>
                  {options.map((option, index) => (
                    <option key={index} value={index}>
                      {option}
                    </option>
                  ))}
                </select>
              </td>
              <td>
                <input
                  type="text"
                  name="amount"
                  value={formData.amount}
                  onChange={handleChange}
                />
              </td>
              <td className='column-button'>
                <button type="submit">Add Money</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </form>
  );
};


// console.log('howMuch', howMuch);

// const info = {
//     post: {
//         status: 'published',
//         title: `${person}-${what}-${option}-${howMuch}`,
//         html: `{"who":"${person}","what":"${what}","option":"${option}","value":"${howMuch}"}`,
//         tags: [person, 'money'],
//         featured: true,
//     },
// };

// // Assuming this.appService.addObject is an HTTP service for making API calls
// apiService.addObject('posts', info).then((res: any) => {
//     console.log('Return from addMoney', res);
//     // You might want to use state to trigger a re-render or perform other actions here
// });
    
//   const addTodo = () => {
//     const newTodo = { "todo": { "id": todos.length + 1, "title": inputTitle, "completed": false }, "id": 1 }
//     apiService.addObject('todos', newTodo)
//         .then(data => {
//             getAllTodos();
//         })
//         .catch(error => {
//             console.error('Error:', error);
//         });
//     setInputTitle('');

// };