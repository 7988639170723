import { useState, useEffect } from "react";
import { apiLink } from "./radApi";

interface Post {
    id: number;
    title: string;
    html: string;
    featured: boolean;
    slug: string | null;
    url: string | null;
}

export const API_BASE_URL = "http://localhost:8000"; // Update this to your actual backend URL
// export const graphqlLink = apiLink+'/graphql/';

const usePostManager = () => {
    const [posts, setPosts] = useState<Post[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    // Fetch all posts
    const fetchPosts = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch(`${apiLink}/posts/`);
            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }
            const data = await response.json();
            if (data.success) {
                setPosts(data.posts);
            } else {
                setError("Failed to fetch posts.");
            }
        } catch (err: any) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    //   const newPost = {
    //     title: "New Post Title",
    //     html: "<p>This is a new post.</p>",
    //     featured: true,
    //   };

    // // Create a new post
    // const createPost = async (newPost: Omit<Post, "id" | "slug" | "url">) => {
    //     try {
    //         const newPost = {
    //             title: "New Post Title",
    //             html: "<p>This is a new post.</p>",
    //             featured: true,
    //         };
    //         // body: JSON.stringify({
    //         //     query: `
    //         //       query {
    //         //         getPosts(date: "${dataForAPI.getForDate}", tagIds: ${JSON.stringify(dataForAPI.tagIds)}, byId: ${JSON.stringify(dataForAPI.userId)}) {
    //         //           id
    //         //           title
    //         //           data
    //         //           created
    //         //           html
    //         //         }
    //         //       }
    //         //     `,
    //         //   }),
    //         const response = await fetch(`${API_BASE_URL}/posts/create/`, {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //             },
    //             body: JSON.stringify(newPost),
    //         });
    //         if (!response.ok) {
    //             throw new Error(`Error: ${response.statusText}`);
    //         }
    //         const data = await response.json();
    //         if (data.success) {
    //             // Refetch posts to update the list
    //             fetchPosts();
    //         } else {
    //             setError("Failed to create post.");
    //         }
    //     } catch (err: any) {
    //         setError(err.message);
    //     }
    // };
    const createPost = async (dataForAPI: { getForDate: string; tagIds: number[]; userId: number }) => {
        const postData = {
          date: dataForAPI.getForDate,
          tagIds: dataForAPI.tagIds,
          userId: dataForAPI.userId,
        };
      
        try {
          const response = await fetch(`${apiLink}/posts/create/`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(postData),
          });
      
          if (!response.ok) {
            throw new Error(`Error: ${response.statusText}`);
          }
      
          const result = await response.json();
      
          if (!result.success) {
            throw new Error(result.message || "Failed to create post.");
          }
      
          // Handle success
          console.log("Post created successfully:", result);
          return result;
        } catch (error: any) {
          console.error("Failed to create post:", error.message);
          throw error;
        }
      };
      

    // Delete a post
    const deletePost = async (postId: number) => {
        try {
            const response = await fetch(`${apiLink}/posts/${postId}/delete/`, {
                method: "POST",
            });
            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }
            const data = await response.json();
            if (data.success) {
                // Refetch posts to update the list
                fetchPosts();
            } else {
                setError("Failed to delete post.");
            }
        } catch (err: any) {
            setError(err.message);
        }
    };

    useEffect(() => {
        fetchPosts();
    }, []);

    return {
        posts,
        error,
        loading,
        fetchPosts,
        createPost,
        deletePost,
    };
};

export default usePostManager;
