import React, { useEffect, useState } from "react";
import WalletQRCode from "./WalletQRCode";
import api from "./api"; // Axios instance

const WalletDetails: React.FC = () => {
  const [wallet, setWallet] = useState<{ publicAddress: string } | null>(null);

  useEffect(() => {
    const fetchWallet = async () => {
      try {
        const response = await api.get("/wallet/"); // Replace with your API endpoint
        setWallet(response.data);
      } catch (error) {
        console.error("Failed to fetch wallet details", error);
      }
    };

    fetchWallet();
  }, []);

  if (!wallet) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <h2>My Wallet</h2>
      <WalletQRCode publicAddress={wallet.publicAddress} />
    </div>
  );
};

export default WalletDetails;


// import React from "react";
// import { useWallet } from "./useBitcoinAPI";

// const WalletDetails: React.FC = () => {
//   const { wallet, loading } = useWallet();

//   if (loading) return <p>Loading wallet details...</p>;

//   return (
//     <div>
//       <h2>Wallet Details</h2>
//       {wallet ? (
//         <div>
//           <p>Public Address: {wallet.public_address}</p>
//           <p>Balance: {wallet.balance} BTC</p>
//         </div>
//       ) : (
//         <p>No wallet found.</p>
//       )}
//     </div>
//   );
// };

// export default WalletDetails;
