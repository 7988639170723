import React, { useState, useEffect } from 'react';
import "./blinking-circles.scss";

export interface IBlinkingCirclesProps {
  isWebSite: boolean;
  numberOfCircles: number;
}

const Circles: React.FC<IBlinkingCirclesProps> = ({ isWebSite, numberOfCircles }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [circles, setCircles] = useState<Array<{ id: number; isVisible: boolean }>>(() => {
    let returnArray = [];
    for (let i = 0; i < numberOfCircles; i++)
      returnArray.push({ id: i, isVisible: false });
    return returnArray;
  });



  useEffect(() => {
    const intervalId = setInterval(() => {
      // Randomly select a circle to toggle visibility
      const randomIndex = Math.floor(Math.random() * circles.length);
      const updatedCircles = circles.map(circle =>
        circle.id === circles[randomIndex].id
          ? { ...circle, isVisible: !circle.isVisible }
          : circle
      );
      setCircles(updatedCircles);
    }, 300); // Blink every 1000 milliseconds (1 second)

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [circles]);

  return (
        <span className='circles'>
          {circles.map(circle => (
            <span
              key={circle.id}
              style={{
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                backgroundColor: 'red',
                margin: '5px',
                display: circle.isVisible ? 'inline-block' : 'none',
              }}
            ></span>
          ))}
        </span>
  );
};

export default Circles;
