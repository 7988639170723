import React from "react";
// import QRCode from "qrcode.react";

interface WalletQRCodeProps {
  publicAddress: string;
}

const WalletQRCode: React.FC<WalletQRCodeProps> = ({ publicAddress }) => {
  if (!publicAddress) {
    return <p>No public address available</p>;
  }

  return (
    <div style={{ textAlign: "center" }}>
      <h3>Wallet QR Code</h3>
      {/* <QRCode value={publicAddress} size={256} /> */}
      <p>{publicAddress}</p>
    </div>
  );
};

export default WalletQRCode;
