import React, { useEffect, useState } from 'react';

export const ShowLocation: React.FC = () => {
  const [location, setLocation] = useState<string>("");

  useEffect(() => {
    const x = document.getElementById("demo");

    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(showPosition);
      } else {
        if (x) {
          x.innerHTML = "Geolocation is not supported by this browser.";
        }
      }
    };

    const showPosition = (position: GeolocationPosition) => {
      if (x) {
        x.innerHTML =
          "<span>Lat: " +
          position.coords.latitude +
          `</span><span class='width-spacer-10'></span><span class='width-spacer-10'></span><span>Lon: ` +
          position.coords.longitude + "</span>"
      }
    };

    getLocation();

    // Cleanup function
    return () => {
      if (x) {
        x.innerHTML = ""; // Clear the innerHTML when component unmounts
      }
    };
  }, []); // Empty dependency array to run this effect only once

  return (
    <div id="demo">
      {/* {location}  */}
      
      {/* Render the location state */}
    </div>
  );
};
