import React from "react";
import { useBitcoinHolding } from "./useBitcoinAPI";

const PortfolioOverview: React.FC = () => {
  const { holding, loading } = useBitcoinHolding();

  if (loading) return <p>Loading portfolio...</p>;

  return (
    <div>
      <h2>Portfolio Overview</h2>
      {holding ? (
        <div>
          <p>Total Holdings: {holding.total_holdings} BTC</p>
          <p>Last Updated: {new Date(holding.last_updated).toLocaleString()}</p>
        </div>
      ) : (
        <p>No holdings found.</p>
      )}
    </div>
  );
};

export default PortfolioOverview;
